import React from "react";
import LandingFooter from "../shared/LandingFooter";
import LandingHeader from "../shared/LandingHeader";
import MobileApp from "../shared/MobileApp";
import privacylist from '../../../public/img/privacylist.png';

const PrivacyPolicy = () => {
return(
    <>
    <LandingHeader/>

    <main className="Innerbg">
        <div className="terms_conditions">
            <div className="container">
                <div id="datail-banner">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Privacy Policy</li>
                        </ol>
                    </nav>
                </div>
                <h3 className="mainHeading">Privacy Policy</h3>
                <h4 className="privacy_subHeading">Summary of TimelyRecruiter’s Privacy Policy</h4>
                <p className="inner_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                <p className="inner_text">It has survived not only five centuries, but also the leap into popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                <p className="inner_text">It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing.</p>
                <h6 className="inner_subheading">1. Introduction</h6>
                <p className="inner_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                    Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type
                    and scrambled it to make a type specimen book. It has survived not only five centuries, but also the
                    leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s
                    with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
                    publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <h6 className="inner_subheading">2. Data collection and use</h6>
                <p className="inner_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </p>
                <h6 className="inner_subheading">3. Where does it come from?</h6>
                <p className="inner_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </p>
                <ul className="inner_notes mb-4">
                    <li>
                        <img src={privacylist} alt="icon" className="img-fluid  rightIcon"/>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</span>
                    </li>
                    <li>
                        <img src={privacylist} alt="icon" className="img-fluid  rightIcon"/>
                        <span>Make a type specimen book. It has survived not only five centuries, but also the leap into popularised.</span>
                    </li>
                    <li>
                        <img src={privacylist} alt="icon" className="img-fluid  rightIcon"/>
                        <span>With the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text.</span>
                    </li>
                    <li>
                        <img src={privacylist} alt="icon" className="img-fluid  rightIcon"/>
                        <span>The printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                    </li>
                    <li>
                        <img src={privacylist} alt="icon" className="img-fluid  rightIcon"/>
                        <span>Richard McClintock, a
                            Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure
                            Latin
                            words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in
                            classical literature, discovered the undoubtable source.</span>
                    </li>
                </ul>
                <p className="inner_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </p>    
                <p className="inner_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
                when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </p>          
                <p className="inner_text">It has survived not only five centuries, but also the leap into popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
                </p>
                <p className="inner_text"> It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing.  </p>
                <h6 className="inner_subheading">4. Finibus Bonorum et Malorum</h6>
                <p className="inner_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </p>
                <h6 className="inner_subheading">5. Sed Ut Perspiciatis Unde</h6>
                <p className="inner_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </p>
                <h6 className="inner_subheading">6. Lorem Ipsum Dolor</h6>
                <p className="inner_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
                </p>

                <ul className="inner_notes mb-4">
                    <li>
                        <img src={privacylist} alt="icon" className="img-fluid  rightIcon"/>
                        <span>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.</span>
                    </li>
                    <li>
                        <img src={privacylist} alt="icon" className="img-fluid  rightIcon"/>
                        <span>Make a type specimen book. It has survived not only five centuries, but also the leap into popularised.</span>
                    </li>
                    <li>
                        <img src={privacylist} alt="icon" className="img-fluid  rightIcon"/>
                        <span>With the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text.</span>
                    </li>
                    <li>
                        <img src={privacylist} alt="icon" className="img-fluid  rightIcon"/>
                        <span>The printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</span>
                    </li>
                  
                </ul>
                <p className="inner_text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.            </p>
            </div>
        </div>
    </main>
    
    <MobileApp/>
    <LandingFooter/>
    </>
)
}
export default PrivacyPolicy