import React from "react";
import LandingFooter from "../shared/LandingFooter";
import LandingHeader from "../shared/LandingHeader";
import MobileApp from "../shared/MobileApp";
import scrolldown from '../../../public/img/scrolldown.png';
import reviewRight from '../../../public/img/reviewRight.png';
import reviewShape from '../../../public/img/reviewShape.png';
import qoutes from '../../../public/img/qoutes.png';
import reviewitemshape from '../../../public/img/reviewitemshape.png';
const Review = () => {
    return (
        <>
            <LandingHeader />
            <main>
                <div className="bannerArea reviewBanner">
                    <figure className="reviewShape"><img src={reviewShape} /></figure>
                    <div className="container">
                        <div id="datail-banner">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="#">Home</a></li>
                                    <li className="breadcrumb-item active" aria-current="page">Reviews</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6 col-sm-12 bannerRES order-last">
                                <div className="bannerAreaRight">
                                    <img className="img-fluid" src={reviewRight} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12 bannerRES">
                                <div className="bannerAreaLeft">
                                    <h1>What People Say <span>About TimelyRecruiter</span></h1>
                                    <h4>Customer Reviews.</h4>
                                    <p>Fully managed integrated and automated recruitment platform and ATS for Individual Recruiters, Small-, & Large Businesses.</p>
                                    <img className="img-fluid" src={scrolldown} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="reviewItems">
                    <figure className="reviewitemshape"><img className="img-fluid" src={reviewitemshape} /></figure>
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 col-lg-4">
                                <div className="item">
                                    <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                                    <div className="testimonials_item">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                        <h4>Leena Roi</h4>
                                        <h5>Manager of Twitter </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="item">
                                    <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                                    <div className="testimonials_item">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                        <h4>Leena Roi</h4>
                                        <h5>Manager of Twitter </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="item">
                                    <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                                    <div className="testimonials_item">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                        <h4>Leena Roi</h4>
                                        <h5>Manager of Twitter </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="item">
                                    <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                                    <div className="testimonials_item">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                        <h4>Leena Roi</h4>
                                        <h5>Manager of Twitter </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="item">
                                    <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                                    <div className="testimonials_item">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                        <h4>Leena Roi</h4>
                                        <h5>Manager of Twitter </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="item">
                                    <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                                    <div className="testimonials_item">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                        <h4>Leena Roi</h4>
                                        <h5>Manager of Twitter </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="item">
                                    <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                                    <div className="testimonials_item">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                        <h4>Leena Roi</h4>
                                        <h5>Manager of Twitter </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="item">
                                    <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                                    <div className="testimonials_item">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                        <h4>Leena Roi</h4>
                                        <h5>Manager of Twitter </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 ">
                                <div className="item">
                                    <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                                    <div className="testimonials_item">
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                                        <h4>Leena Roi</h4>
                                        <h5>Manager of Twitter </h5>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="loadbtn">
                                    <a href="#" className="secondary_btn">Load More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <MobileApp />
            <LandingFooter />
        </>
    )
}
export default Review