import React from "react";
import { Link } from 'react-router-dom';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import LandingHeader from "../shared/LandingHeader";
import AfterloginHeader from "../shared/AfterloginHeader";
import LandingFooter from "../shared/LandingFooter";
import MobileApp from "../shared/MobileApp";
import bannerRight from '../../../public/img/bannerRight.png';
import Getstarted from '../../../public/img/Getstarted.png';
import Manage from '../../../public/img/Manage.png';
import Talent from '../../../public/img/Talent.png';
import Postjob from '../../../public/img/Postjob.png';
import Leads from '../../../public/img/Leads.png';
import Helpbefore from '../../../public/img/Helpbefore.png';
import Chooseleft from '../../../public/img/Chooseleft.png';
import choosebefore from '../../../public/img/choosebefore.png';
import chooseAfter from '../../../public/img/chooseAfter.png';
import Aboutimg from '../../../public/img/Aboutimg.png';
import Aboutshape from '../../../public/img/Aboutshape.png';
import aboutShape1 from '../../../public/img/aboutShape1.png';
import qoutes from '../../../public/img/qoutes.png';
import testShape from '../../../public/img/testShape.png';
import benifitshape from '../../../public/img/benifitshape.png';
import benifitshape2 from '../../../public/img/benifitshape2.png';
const options = {
  margin: 30,
  responsiveClass: true,
  nav: true,
  autoplay: false,
  navText: ["Prev", "Next"],
  smartSpeed: 1000,
  responsive: {
      0: {
          items: 1,
      },
      400: {
          items: 1,
      },
      600: {
          items: 1,
      },
      700: {
          items: 2,
      },
      1000: {
          items: 2,
      }
  },
};
const Home = (props) => {
  return (
    <>
      {/* Start Header */}

      <AfterloginHeader />

      <main>  
        {/* Start Banner Html */}
        <div className="bannerArea">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-6 col-sm-12 bannerRES order-last">
                <div className="bannerAreaRight">
                  <img className="img-fluid" src={bannerRight} />
                  <div className="getStarted">
                    <Link to="#"><img src={Getstarted} /></Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 bannerRES">
                <div className="bannerAreaLeft">
                  <h1>#1 ATS <span>Management and</span> <span>Candidate Recruiting </span></h1>
                  <h4>Platform your Organization, Jobs, or Clients.</h4>
                  <p>Fully managed integrated and automated recruitment platform and ATS for Individual Recruiters, Small-, & Large Businesses.</p>
                  <div className="bannerbtn">
                    <Link to="#" className="primary_btn">Post a Job</Link>
                    <Link to="#" className="secondary_btn">Learn More</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Banner Html */}

        {/* Start How Help in Business*/}
        <div className="businessHelp">
          <figure className="shapeHelp"><img src={Helpbefore} /></figure>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-lg-5 ">
                <div className="headingOther">
                  <h2>How Timely Recruiter Help in Your Business</h2>
                </div>
              </div>
            </div>
            <div className="row">
              <div className=" col-md-6 col-lg-3">
                <div className="businnerInner">
                  <img src={Manage} />
                  <h5>Manage Workspaces</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="businnerInner">
                  <img src={Talent} />
                  <h5>Manage Talents </h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="businnerInner">
                  <img src={Postjob} />
                  <h5>Post Jobs</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
              </div>
              <div className="col-md-6 col-lg-3">
                <div className="businnerInner">
                  <img src={Leads} />
                  <h5>Manage Leads </h5>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End How Help in Business*/}

        {/* Start Whychoose Us*/}
        <div className="whyChoose-main">
          <figure className="chooseBefore"><img className="img-fluid" src={choosebefore} /></figure>
          <figure className="chooseAfter"><img className="img-fluid" src={chooseAfter} /></figure>
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="whychooseLeft">
                  <img className="img-fluid" src={Chooseleft} />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="whychooseRight">
                  <h4>Why Choose Us</h4>
                  <div className="chooseContent">
                    <span>1</span>
                    <article>
                      <h5>Connect Your ATS</h5>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    </article>
                  </div>
                  <div className="chooseContent">
                    <span>2</span>
                    <article>
                      <h5>Publish Open Roles</h5>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    </article>
                  </div>
                  <div className="chooseContent">
                    <span>3</span>
                    <article>
                      <h5>Add Talents</h5>
                      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Whychoose Us*/}

        {/* Start Benifits Timely Recruiter*/}
        <div className="timlyBenifits">
          <figure className="benifitShape1"><img src={benifitshape}/></figure>
          <figure className="benifitShape2"><img src={benifitshape2}/></figure>
          <div className="upprHeading">
            <h2>Benifits of Timely Recruiter</h2>
          </div>
          <div className="baenifitMain">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="benifitInner">
                    <figure>
                      <img className="img-fluid" src={Manage} />
                    </figure>
                    <article>
                      <h4>Lorem Ipsum dummy.</h4>
                      <p>Lorem ipsum dolor sit amet, consect
                        adipiscing elit, sed do eiusmod tempor.
                        orem ipsum dolor sit amet.</p>
                    </article>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="benifitInner">
                    <figure>
                      <img className="img-fluid" src={Talent} />
                    </figure>
                    <article>
                      <h4>Lorem Ipsum dummy.</h4>
                      <p>Lorem ipsum dolor sit amet, consect
                        adipiscing elit, sed do eiusmod tempor.
                        orem ipsum dolor sit amet.</p>
                    </article>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="benifitInner">
                    <figure>
                      <img className="img-fluid" src={Postjob} />
                    </figure>
                    <article>
                      <h4>Lorem Ipsum dummy.</h4>
                      <p>Lorem ipsum dolor sit amet, consect
                        adipiscing elit, sed do eiusmod tempor.
                        orem ipsum dolor sit amet.</p>
                    </article>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="benifitInner">
                    <figure>
                      <img className="img-fluid" src={Leads} />
                    </figure>
                    <article>
                      <h4>Lorem Ipsum dummy.</h4>
                      <p>Lorem ipsum dolor sit amet, consect
                        adipiscing elit, sed do eiusmod tempor.
                        orem ipsum dolor sit amet.</p>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Benifits Timely Recruiter*/}

        {/* Start About section*/}
        <div className="AboutMain">
          <figure className="abutShape"><img src={Aboutshape} /></figure>
          <figure className="abutShape1"><img src={aboutShape1} /></figure>
          <div className="container">
            <div className="row">
            <div className="col-lg-6 col-md-12 order-last">
                <div className="aboutRight">
                  <img className="img-fluid" src={Aboutimg} />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="aboutLeft">
                  <h4>Lorem Ipsum is simply dummy text of the printing  typesetting industry.</h4>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                  <p>It has survived not only five centuries, but also the leap into popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages.</p>
                  <Link to="#" className="primary_btn">Get Started Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About section*/}

        {/* Start Testimonial SEction */}
        <div className="our_testimonials pt-5">
          <figure className="testShape"><img src={testShape} /></figure>
          <div className="container">
            <div className="row justify-content-center  ">
              <div className="col-lg-5 col-12">
                <div className="headingOther">
                  <h2>What People Say About Timely Recruiter</h2>
                </div>
              </div>
            </div>
            <div className="row">
            <OwlCarousel className='owl-theme' loop items={2} margin={15} autoplay nav {...options}>
              <div className="item">
                <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                <div className="testimonials_item">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  <h4>Jhone Due</h4>
                  <h5>CEO of Apple </h5>
                </div>
              </div>
              <div className="item">
                <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                <div className="testimonials_item">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  <h4>Leena Roi</h4>
                  <h5>Manager of Twitter </h5>
                </div>
              </div>
              <div className="item">
                <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                <div className="testimonials_item">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  <h4>Leena Roi</h4>
                  <h5>Manager of Twitter </h5>
                </div>
              </div>
              <div className="item">
                <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                <div className="testimonials_item">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  <h4>Leena Roi</h4>
                  <h5>Manager of Twitter </h5>
                </div>
              </div>
              <div className="item">
                <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                <div className="testimonials_item">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  <h4>Leena Roi</h4>
                  <h5>Manager of Twitter </h5>
                </div>
              </div>
              <div className="item">
                <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                <div className="testimonials_item">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  <h4>Leena Roi</h4>
                  <h5>Manager of Twitter </h5>
                </div>
              </div>
              <div className="item">
                <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                <div className="testimonials_item">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  <h4>Leena Roi</h4>
                  <h5>Manager of Twitter </h5>
                </div>
              </div>

              <div className="item">
                <i className="qoutes_icon"><img src={qoutes} alt="" /></i>
                <div className="testimonials_item">
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                  <h4>Leena Roi</h4>
                  <h5>Manager of Twitter </h5>
                </div>
              </div>
              </OwlCarousel>
            </div>
          </div>
        </div>

        {/* End Testimonial SEction */}
      </main>
      <MobileApp/>
      <LandingFooter />
    </>
  );
            } 
export default (Home);
