import React from "react";
import { Link } from 'react-router-dom';
import appicon from '../../../public/img/appicon.png';
import playapp from '../../../public/img/playapp.png';

const MobileApp = () => {
    
return(
    <>
    <div className="downloadApp">
    <div className="container">
      <div className="row ">
        <div className="col-sm-12">
          <div className="dowlnloadHeading">
            <h5>Download</h5>
            <h2>The MobileApp Now</h2>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4 col-md-6">
          <div className="downloadInner">
            <h6>For Recruiter</h6>
            <div className="appbtn">
              <Link to="#">
                <img src={appicon} />
                <h4>App Store</h4>
                </Link>
                <Link to="#">
                <img src={playapp} />
                <h4>Play Store</h4>
                </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="downloadInner">
            <h6>For Candidate</h6>
            <div className="appbtn">
              <Link to="#">
                <img src={appicon} />
                <h4>App Store</h4>
                </Link>
                <Link to="#">
                <img src={playapp} />
                <h4>Play Store</h4>
                </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6">
          <div className="downloadInner">
            <h6>For Clients</h6>
            <div className="appbtn">
              <Link to="#">
                <img src={appicon} />
                <h4>App Store</h4>
                </Link>
                <Link to="#">
                <img src={playapp} />
                <h4>Play Store</h4>
                </Link>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  
  </>
);
};

export default MobileApp;
