import React from 'react';
import { Link } from 'react-router-dom';
import DashLogo from "../../../../public/img/DashLogo.png";
import dashboard from "../../../../public/img/dashboard.png";
import workspace from "../../../../public/img/workspace.png";
import talent from "../../../../public/img/talent.png";
import leads from "../../../../public/img/leads.png";
import incandidate from "../../../../public/img/incandidate.png";
import setting from "../../../../public/img/setting.png";
import postJob from "../../../../public/img/postJob.png";

const Sidebar = () => {
    return (
        <div className='sideMainn'>
            <aside className="main-sidebar sidebar-dark-primary elevation-4">
                <a className="brand-link">
                    {/* <span className="brand-text font-weight-bold">Shepherd</span> */}
                    <img src={DashLogo} className="brand-text font-weight-light" alt="Brand Logo"/>
                </a>
                <div className="sidebar">
                    <nav className="sidenavv">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="true">
                            <li className="nav-item active">
                                <a className="nav-link">
                                    <img src={dashboard} />
                                    <p>Dashboard</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link">
                                <img src={workspace} />
                                    <p>Workspace</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link">
                                <img src={talent} />
                                    <p>Talent</p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link">
                                <img src={leads} />
                                    <p>Leads </p>
                                </a>
                            </li>

                            <li className="nav-item">
                                <a className="nav-link">
                                <img src={incandidate} /> 
                                    <p>Interested Candidates </p>
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link">
                                <img src={setting} /> 
                                    <p>Settings </p>
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className='sidenavBottom'>
                         <Link to="#" className="postjobBtn"><img src={postJob}/>Post Jobs </Link>
                         <Link to="#" className="logoutbBtn">Log Out</Link>
                    </div>
                </div>
            </aside>
        </div>
    )
}
export default Sidebar