import {Routes ,Route} from "react-router-dom";
// import * as Layouts from "./../app/layouts";
import * as Containers from "./../app/containers";
function Router() {
  return (
    <Routes>
      {/* Auth Routes*/}
      {/* <Layouts.AuthRoute exact path="/" component={Containers.Home} /> */}

      <Route exact path="/" element={<Containers.Home />}/>
      <Route exact path="/login" element={<Containers.Login />}/>
      <Route exact path="/register" element={<Containers.Register />}/>
      <Route exact path="/privacyPolicy" element={<Containers.privacyPolicy />}/>
      <Route exact path="/TermsConditions" element={<Containers.termsConditions />}/>
      <Route exact path="/About" element={<Containers.about />}/>
      <Route exact path="/FAQ" element={<Containers.FAQ />}/>
      <Route exact path="/review" element={<Containers.review />}/>
      <Route exact path="/contact" element={<Containers.Contact />}/>
      <Route exact path="/index" element={<Containers.index />}/>
      <Route exact path="/forgot-password" element={<Containers.ForgotPassword/>}/>
      <Route exact path="/Createpassword" element={<Containers.Createpassword/>}/>

    </Routes>
  );
}

export default (Router);
