import React , { useState } from "react";
import { Link } from 'react-router-dom';
import Logo from '../../../public/img/Logo.png';
import loginGirl from '../../../public/img/loginGirl.png';
const Register = (props) => {
    const [passwordType , setPasswordType] = useState(true);
    const [eye , setEye] = useState(true);

    const changeEye = () => {
        setEye(!eye)
        setPasswordType(!passwordType)
    }
    return (
        <>
    {/* Start SignUp Page */}
    <section className="loginMain registermain"> 
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-5 col-md-12">
                    <div className="loginContent">
                            <div className="logo">
                                <Link to="/"><img className="img-responsive" src={Logo} /></Link>
                            </div>
                            <div className="loginForm mt-4">
                            <form className="loginHorizontalForm_">
                                <h3 className="mb-1">Create an Account</h3>
                                <p className="mb-4">Enter your details below.</p>
                                <div className="groupInputs_ mb-4">
                                    <label className="loginLabel">
                                        Name
                                    </label>
                                <input type="text" className="form-control" id="" placeholder="Enter Name" />
                                </div>
                                <div className="groupInputs_ mb-4">
                                    <label className="loginLabel">
                                        Email
                                    </label>
                                <input type="email" className="form-control" id="" placeholder="Email" />
                                </div>
                                <div className=" groupInputs_  mb-4">
                                <label className="loginLabel">
                                   Password  
                                </label>
                                <input type={passwordType?'password':'text'} className="form-control id_password" id="exampleInputPassword1" placeholder="Password" />
                                <span className="toggle_password_">
                                    <span onClick={changeEye} className={eye ? 'show-icon' : 'hide-icon'}></span>
                                </span>
                                </div>
                                <div className="loginbtn">
                                    <button type="submit" className="primary_btn">Create Account</button>
                                </div>
                            </form>
                        </div>
                        <div className="loginBottom">
                            <p>Have an account? <Link to="/login">Login</Link></p>

                        </div>
                    </div>
                </div>
                <div className=" col-lg-6 d-lg-block d-none ">
                    <div className="loginImage registerImage">
                        <img className="img-responisve" src={loginGirl} />
                    </div>
                </div>
            </div>
            </div>
    </section>
    {/* End SignUp Page */}
        </>

    );
};


export default (Register);