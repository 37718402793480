import React from "react";
import Navbar from "./commom/Navbar";
import Sidebar from "./commom/Sidebar";
const index = (props) => {
  return (
    <>
      <div className="wrapper">
        <Navbar />
        <Sidebar />

        <div className="content-wrapper">
          <h1>Hello</h1>
        </div>
      </div>

    </>
  );
}

export default (index);