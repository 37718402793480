import React from "react";
import { Link } from 'react-router-dom';
import Logo from '../../../public/img/Logo.png';
import forgotimg from '../../../public/img/forgotimg.png';

const ForgotPassword = () => {
    return (
        <> 
        <section className="loginMain forgotMain">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-5 col-md-12">
                            <div className="loginContent">
                                <div className="logo">
                                  <Link to="/"><img src={Logo} /></Link>  
                                </div>
                                <div className="loginForm mt-4">
                                    <form className="loginHorizontalForm_">
                                        <h3 className="mb-1">Reset your password</h3>
                                        <p className="mb-4">Enter the email address associated with your account, and we'll send you a link to reset your password.  </p>
                                        <div className="groupInputs_ mb-4">
                                            <label className="loginLabel">
                                                Email
                                            </label>
                                            <input type="email" className="form-control" id="" placeholder="rajat758@gmail.com" />
                                        </div>
                                        <div className="loginbtn">
                                            <button type="submit" className="primary_btn">Create Account</button>
                                            <Link to="/login" className="returnSign">Return to sign in</Link>
                                        </div>
                                    </form>
                                </div>
                                <div className="loginBottom">
                                    <p>Don’t have an account?<Link to="/Register"> Create new Account</Link></p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-6 d-lg-block d-none">
                            <div className="loginImage">
                                <img src={forgotimg} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>         

)};

export default (ForgotPassword);