import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./routes";
import "./public/css/custom.css";
import "./public/css/Responsive.css";
import "./public/css/Dashboard.css";


const App = () => {
  return (
    <React.StrictMode>
        <BrowserRouter>
            <Router />
        </BrowserRouter>
    </React.StrictMode>
  );
};

export default App;
