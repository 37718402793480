import React from "react";
import { Link } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import profileimg from '../../../../public/img/profileimg.png';
import logouticon from '../../../../public/img/logouticon.png';
const Navbar = (props) => {
    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">

                <div className="headleft">
                    <h4>Dashboard</h4>
                    <span>Welcome Rick!</span>
                </div>
                <div className="searchbox">
                <button type="submit" className="searchbtn"><i class="fa fa-search"></i></button>
                <input type="text" placeholder="Search.." name="search" />
                </div>
                <div className="rightNavbar_">
                    <div className="notificationBox">

                    </div>
                    <ul className="navbar-nav">
                        <li className="nav-item dropdown">
                            <ul className="midRightMenus">
                                <li className="midRightItems dropdown">
                                    <Dropdown>
                                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="btndrop">
                                            <span className="">
                                                <img src={profileimg} className="img-fluid" />
                                            </span>
                                            <span className="menusInnerTxt"><span>Rick</span>
                                                <i className="fas fa-chevron-down ms-2"></i></span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <div className="userProfileArea">
                                                <img src={profileimg} className="img-fluid" />
                                                <div className="userProfileDetails_">
                                                    <h6 className="userName_">Rick Sumith</h6>
                                                    <h5 className="userEmail">jhonesumith45@gmail.com</h5>
                                                    <a href="" className="userLink_">View and edit profile</a>
                                                </div>
                                            </div>
                                            <Dropdown.Item href="/login" className="logoutBtn"><img src={logouticon} />Logout</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
};
export default (Navbar);