import React from "react";
import LandingFooter from "../shared/LandingFooter";
import LandingHeader from "../shared/LandingHeader";
import MobileApp from "../shared/MobileApp";
import aboutbot from '../../../public/img/aboutbot.png';
import Aboutimg from '../../../public/img/Aboutimg.png';
import Aboutshape from '../../../public/img/Aboutshape.png';
import Manage from '../../../public/img/Manage.png';
import Talent from '../../../public/img/Talent.png';
import Postjob from '../../../public/img/Postjob.png';
import Leads from '../../../public/img/Leads.png';
import innerabout1sahpe from '../../../public/img/innerabout1sahpe.png';
import innerabout2sahpe from '../../../public/img/innerabout2sahpe.png';

const About = () => {
    return (
        <>
            <LandingHeader />

            <main>
                <div className="AboutUsinner">
                <figure className="aboutInnershape1"><img src={innerabout1sahpe} /></figure>
                <figure className="aboutInnershape2" ><img src={innerabout2sahpe} /></figure>

                    <div className="container">
                        <div className="row">
                            <div id="datail-banner">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">About Us</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-12">
                                <div className="aboutContent">
                                    <h2>About Us</h2>
                                    <h5>Our Story</h5>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy textever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                                    <img className="img-fluid" src={aboutbot} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="AboutMain innertextabout">
                    <figure className="abutShape"><img src={Aboutshape} /></figure>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-12 order-last">
                                <div className="aboutRight">
                                    <img className="img-fluid" src={Aboutimg} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="aboutLeft">
                                    <h4>Our Gloal is Grow to your business with #1 ATS Management and Candidate Recruiting Platform </h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
                                    <p>It has survived not only five centuries, but also the leap into popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="businessHelp">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-5 ">
                                <div className="headingOther">
                                    <h2>How Timely Recruiter Help in Your Business</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className=" col-md-6 col-lg-3">
                                <div className="businnerInner">
                                    <img src={Manage} />
                                    <h5>Manage Workspaces</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="businnerInner">
                                    <img src={Talent} />
                                    <h5>Manage Talents </h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="businnerInner">
                                    <img src={Postjob} />
                                    <h5>Post Jobs</h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-3">
                                <div className="businnerInner">
                                    <img src={Leads} />
                                    <h5>Manage Leads </h5>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="timlyBenifits aboutBenefit">
                    <div className="upprHeading">
                        <h2>Benifits of Timely Recruiter</h2>
                    </div>
                    <div className="baenifitMain">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="benifitInner">
                                        <figure>
                                            <img className="img-fluid" src={Manage} />
                                        </figure>
                                        <article>
                                            <h4>Lorem Ipsum dummy.</h4>
                                            <p>Lorem ipsum dolor sit amet, consect
                                                adipiscing elit, sed do eiusmod tempor.
                                                orem ipsum dolor sit amet.</p>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="benifitInner">
                                        <figure>
                                            <img className="img-fluid" src={Talent} />
                                        </figure>
                                        <article>
                                            <h4>Lorem Ipsum dummy.</h4>
                                            <p>Lorem ipsum dolor sit amet, consect
                                                adipiscing elit, sed do eiusmod tempor.
                                                orem ipsum dolor sit amet.</p>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="benifitInner">
                                        <figure>
                                            <img className="img-fluid" src={Postjob} />
                                        </figure>
                                        <article>
                                            <h4>Lorem Ipsum dummy.</h4>
                                            <p>Lorem ipsum dolor sit amet, consect
                                                adipiscing elit, sed do eiusmod tempor.
                                                orem ipsum dolor sit amet.</p>
                                        </article>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="benifitInner">
                                        <figure>
                                            <img className="img-fluid" src={Leads} />
                                        </figure>
                                        <article>
                                            <h4>Lorem Ipsum dummy.</h4>
                                            <p>Lorem ipsum dolor sit amet, consect
                                                adipiscing elit, sed do eiusmod tempor.
                                                orem ipsum dolor sit amet.</p>
                                        </article>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <MobileApp />
            <LandingFooter />
        </>
    )
}
export default About