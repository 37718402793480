import React from "react";
import { Link } from 'react-router-dom';
import LandingFooter from "../shared/LandingFooter";
import LandingHeader from "../shared/LandingHeader";
import MobileApp from "../shared/MobileApp";
import faqicon from '../../../public/img/faqicon.png';
import contactLogo from '../../../public/img/contactLogo.png';

const Contact = () => {
    return (
        <>
            <LandingHeader />
            <main className="Innerbg">
                <section className="frequentQuestion conatctMain">
                    <div className="container">
                        <div className="row">
                            <div id="datail-banner">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active" aria-current="page">Contact Us         </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="faqHeading">
                                    <h2>Get In Touch</h2>
                                    <h5>Do you Have Any query? Just Write us a Message!</h5>
                                    <div className="searchinformation">
                                        <img className="img-fluid" src={faqicon} />
                                        <h4>Support@demoid.com</h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row align-items-center">
                            <div className="col-md-3 col-lg-4 pe-md-0">
                                <div className="contactLeft">
                                    <img className="img-fluid" src={contactLogo} />
                                </div>
                            </div>
                            <div className="col-md-9 col-lg-7 ps-md-0">
                                <div className="contactRight">
                                    <form className="form_Contact horizontal" role="form">
                                    <h4>Send a Message</h4>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="form-group mb-4 col-md-12">
                                                    <label className="contactLabel_txt" for="inputEmailAddress">Name</label>
                                                    <input className="form-control" id="inputEmailAddress" type="text" placeholder="Enter Name"/>
                                                </div>
                                                <div className="form-group mb-4 col-md-12">
                                                    <label className="contactLabel_txt" for="inputPassword">Email</label>
                                                    <input className="form-control id_password" type="text" placeholder="Enter Email Address" />
                                                </div>
                                                <div className="form-group mb-4 col-md-12">
                                                    <label className="contactLabel_txt" for="inputPassword">Phone Number</label>
                                                    <input className="form-control" type="text" placeholder="Enter Phone Number" />
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-4 col-md-12">
                                                    <label className="contactLabel_txt">Message</label>
                                                    <textarea id="contactmsg" rows="4" className="form-control" placeholder="Message Type Here...">
                                                    </textarea>
                                                </div>
                                            </div>
                                            <div className="contactbtn">
                                                <Link to="#" className="primary_btn"> Send Message</Link>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <LandingFooter />
        </>
    )
}
export default Contact