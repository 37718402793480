import React, { useState } from "react";
import Logo from '../../../public/img/Logo.png';
import { Link } from 'react-router-dom';
const LandingHeader = (props) => {
    const [openMenu , setOpenMenu] = useState(false);
  return (
    <>
    <header id="header" className="sticky">
        <section className="headerInner">
        <div className="container">
            <div className="row align-items-center">
            <div className="col-6 col-sm-3 col-lg-2">
                <Link to="/" className="brand_logo">
                <img src={Logo} alt="Logo"/>
                </Link>
            </div>
            <div className="col-6 col-sm-9 col-lg-10">
                <div id="myNav" className="myNav_overlay_">
                <Link to="#" className="closebtn" onClick={() => {setOpenMenu(!openMenu)}} ><i class="fa-solid fa-xmark"></i></Link>
                <ul className="navbar-nav navbar_overlay_content_">
                    <li className="nav-item active">                             
                    <Link to="/" className="nav-link">Home 
                    </Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/About" className="nav-link"> About us
                    </Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/Contact" className="nav-link">Contact Us
                    </Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/Review" className="nav-link"> Reviews
                    </Link>
                    </li>
                </ul>
                <ul className="headerbutton_">
                    <li className="nav-item">
                        <Link to="/Register" className="secondary_btn">Register</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/login" className="primary_btn">Login</Link>
                    </li>
                </ul>
                </div>
                {openMenu 
                      ?
                      <>
                      <div id="myNav2" className="myNav_overlay_2">
                <Link to="#" className="closebtn" onClick={() => {setOpenMenu(!openMenu)}} ><i class="fa-solid fa-xmark"></i></Link>
                <ul className="navbar-nav navbar_overlay_content_">
                    <li className="nav-item active">                             
                    <Link to="/" className="nav-link">Home 
                    </Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/About" className="nav-link"> About us
                    </Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/Contact" className="nav-link">Contact Us
                    </Link>
                    </li>
                    <li className="nav-item">
                    <Link to="/Review" className="nav-link"> Reviews
                    </Link>
                    </li>
                </ul>
                <ul className="headerbutton_">
                    <li className="nav-item">
                        <Link to="/Register" className="secondary_btn">Register</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/login" className="primary_btn">Login</Link>
                    </li>
                </ul>
                </div>
                </>
                :null}
                <span className="openbtn" onClick={() => {setOpenMenu(!openMenu)}}><i class="fa-solid fa-bars"></i></span>
            </div>
            </div>
        </div>
        </section>
    </header>
    </>
  );
};


export default (LandingHeader);
