import React from "react";
import { Link } from 'react-router-dom';
import Logo from '../../../public/img/Logo.png';
import forgotimg from '../../../public/img/forgotimg.png';

const Createpassword = () => {
    return (
        <> 
        <section className="loginMain forgotMain">
                <div className="container">
                    <div className="row justify-content-center align-items-center">
                        <div className="col-lg-5 col-md-12">
                            <div className="loginContent">
                                <div className="logo">
                                  <Link to="/"><img src={Logo} /></Link>  
                                </div>
                                <div className="loginForm mt-4">
                                    <form className="loginHorizontalForm_">
                                        <h3 className="mb-1">Create Password</h3>
                                        <p className="mb-4">New password must be different from previous. </p>
                                        <div className="groupInputs_ mb-4">
                                            <label className="loginLabel">
                                            New Password
                                            </label>
                                            <input type="password" className="form-control" id="" value="1234567889" placeholder="" />
                                            <span>Use minimum 6 characters, and at least one letter and number
</span>
                                        </div>
                                        <div className="groupInputs_ mb-4">
                                            <label className="loginLabel">
                                            Confirm New Password
                                            </label>
                                            <input type="password" className="form-control" id="" placeholder="" />
                                            <span>Both password must match.</span>
                                        </div>
                                        <div className="loginbtn">
                                            <button type="submit" className="primary_btn">Reset Password</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-6 d-lg-block d-none">
                            <div className="loginImage">
                                <img src={forgotimg} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>         

)};

export default (Createpassword);