import React from "react";
import { Link } from 'react-router-dom';
import Logo from '../../../public/img/Logo.png';
import faceBook from '../../../public/img/faceBook.png';
import twitter from '../../../public/img/twitter.png';
import instaGram from '../../../public/img/instaGram.png';
const LandingFooter = (props) => {
  return (
    <>
<div className="footer">
  <div className="container">
    <div className="row">
      <div className="col-md-4">
        <div className="footer_logo">
          <Link to="/">
            <img src={Logo} alt=""/>
            <p>Copyright © 2022 TimelyRecruiter. All Rights Reserved.</p>

          </Link>
        </div>
      </div>
      <div className="col-md-3 moblie_width">
        <h4 className="footerHeading">Menu</h4>
        <ul className="footer_menu">
          <li>
            <Link to="/">Home </Link>  
            </li>
            <li>
            <Link to="/About">About us</Link>    
            </li>
            <li>
            <Link to="/Contact">Contact Us</Link>  
            </li>
            <li>
            <Link to="/Review">Reviews</Link>        
            </li>      
        </ul>
      </div>
      <div className="col-md-3 moblie_width">
        <h4 className="footerHeading">Info</h4>
        <ul className="footer_menu">
          <li>
            <Link to="/TermsConditions">Terms of Use </Link>   
          </li>
          <li>  
            <Link to="/PrivacyPolicy">Privacy policy </Link> 
           </li>
           <li>
           <Link to="/FAQ">Faq</Link> 
           </li>
        </ul>
      </div>
      <div className="col-md-2 moblie_text_c">
        <h4> Social </h4>
        <ul className="footer_socail">
          <li>
            <Link to="#">
             <img src={faceBook} />
            </Link>
          </li>
          <li>
            <Link to="#">
            <img src={twitter} />
            </Link>
          </li>
          <li>
            <Link to="#">
              <img src={instaGram} alt="" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
    
  </div>
</div>    </>
  );
};
export default (LandingFooter);