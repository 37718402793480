import React, { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import Logo from '../../../public/img/Logo.png';
import { Link } from 'react-router-dom';
import dashicon from '../../../public/img/dashicon.png';
import profileimg from '../../../public/img/profileimg.png';
import logouticon from '../../../public/img/logouticon.png';
const AfterloginHeader = (props) => {


    const [openMenu, setOpenMenu] = useState(false);

    return (
        <>
            <header id="header" className="sticky">
                <section className="headerInner afterloginHead">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-6 col-sm-3 col-lg-2">
                                <Link to="/" className="brand_logo">
                                    <img src={Logo} alt="Logo" />
                                </Link>
                            </div>
                            <div className="col-6 col-sm-9 col-lg-10">
                                <div className="rightaftermenu">
                                    <div id="myNav" className="myNav_overlay_">
                                        <button className="closebtn" onClick={() => { setOpenMenu(!openMenu) }} ><i className="fa-solid fa-xmark"></i></button>
                                        <ul className="navbar-nav navbar_overlay_content_">
                                            <li className="nav-item active">
                                                <Link to="/" className="nav-link">Home
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/About" className="nav-link"> About us
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/Contact" className="nav-link">Contact Us
                                                </Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link to="/Review" className="nav-link"> Reviews
                                                </Link>
                                            </li>
                                        </ul>
                                        <ul className="headerbutton_">
                                            <li className="nav-item">
                                                <Link to="/login" className="primary_btn afterloginBtn"><img src={dashicon} />Go To Dashboard</Link>
                                            </li>
                                        </ul>
                                        <ul className="midRightMenus">
                                            <li className="midRightItems dropdown">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="success" id="dropdown-basic" className="btndrop">
                                                        <span className="">
                                                            <img src={profileimg} className="img-fluid" />
                                                        </span>
                                                        <span className="menusInnerTxt"><span>Rick Sumith</span>
                                                            <i className="fas fa-chevron-down ms-2"></i></span>
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <div className="userProfileArea">
                                                            <img src={profileimg} className="img-fluid" />
                                                            <div className="userProfileDetails_">
                                                                <h6 className="userName_">Rick Sumith</h6>
                                                                <h5 className="userEmail">jhonesumith45@gmail.com</h5>
                                                                <a href="" className="userLink_">View and edit profile</a>
                                                            </div>
                                                        </div>
                                                        <Dropdown.Item href="/login" className="logoutBtn"><img src={logouticon} />Logout</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </li>
                                        </ul>
                                    </div>
                                    <ul className="midRightMenus mobileMenu">
                                        <li className="midRightItems dropdown">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="success" id="dropdown-basic" className="btndrop">
                                                    <span className="">
                                                        <img src={profileimg} className="img-fluid" />
                                                    </span>
                                                    <span className="menusInnerTxt"><span>Rick Sumith</span>
                                                        <i className="fas fa-chevron-down ms-2"></i></span>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <div className="userProfileArea">
                                                        <img src={profileimg} className="img-fluid" />
                                                        <div className="userProfileDetails_">
                                                            <h6 className="userName_">Rick Sumith</h6>
                                                            <h5 className="userEmail">jhonesumith45@gmail.com</h5>
                                                            <a href="" className="userLink_">View and edit profile</a>
                                                        </div>
                                                    </div>
                                                    <Dropdown.Item href="/login" className="logoutBtn"><img src={logouticon} />Logout</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                    </ul>
                                    {openMenu
                                        ?
                                        <>
                                            <div id="myNav2" className="myNav_overlay_2">
                                                <button className="closebtn" onClick={() => { setOpenMenu(!openMenu) }} ><i className="fa-solid fa-xmark"></i></button>
                                                <ul className="navbar-nav navbar_overlay_content_">
                                                    <li className="nav-item active">
                                                        <Link to="/" className="nav-link">Home
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="/About" className="nav-link"> About us
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="/Contact" className="nav-link">Contact Us
                                                        </Link>
                                                    </li>
                                                    <li className="nav-item">
                                                        <Link to="/Review" className="nav-link"> Reviews
                                                        </Link>
                                                    </li>
                                                </ul>
                                                <ul className="headerbutton_">
                                                    <li className="nav-item">
                                                        <Link to="/login" className="primary_btn afterloginBtn"><img src={dashicon} />Go To Dashboard</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                        : null}
                                    <span className="openbtn" onClick={() => { setOpenMenu(!openMenu) }}><i className="fa-solid fa-bars"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </header>
        </>
    );
};


export default (AfterloginHeader);
