import React, { useState } from "react";
import { Link } from 'react-router-dom';
import Logo from '../../../public/img/Logo.png';
import loginGirl from '../../../public/img/loginGirl.png';
const Login = (props) => {
    
    const [passwordType , setPasswordType] = useState(true);
    const [eye , setEye] = useState(true);

    const changeEye = () => {
        setEye(!eye)
        setPasswordType(!passwordType)
    }
    return (
        <>
            {/* <!--Start Login Page--> */}
            <section className="loginMain">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-12">
                            <div className="loginContent">
                                <div className="logo">
                                  <Link to="/"><img src={Logo} /></Link>  
                                </div>
                                <div className="loginForm mt-4">
                                    <form className="loginHorizontalForm_">
                                        <h3 className="mb-1">Welcome!</h3>
                                        <p className="mb-4">Login to your account.  </p>
                                        <div className="groupInputs_ mb-4">
                                            <label className="loginLabel">
                                                Email
                                            </label>
                                            <input type="email" className="form-control" id="" placeholder="Email" />
                                        </div>
                                        <div className=" groupInputs_  mb-4">
                                            <label className="loginLabel">
                                                Password   <Link to="/forgot-password">Forgot your password?</Link>
                                            </label>
                                            <input type={passwordType?'password':'text'} className="form-control id_password" id="exampleInputPassword1" placeholder="Password" />
                                            <span className="toggle_password_">
                                                <span onClick={changeEye} className={eye ? 'show-icon' : 'hide-icon'}></span>
                                            </span>
                                        </div>
                                        <div className=" groupInputs_  mb-4">
                                            <div className="form-check">
                                                <input className="form-check-input" id="remember-me" type="checkbox" tabindex="3" />
                                                <label className="form-check-label" for="remember-me"> Remember Me</label>
                                            </div>
                                        </div>

                                        <div className="loginbtn">
                                            <button type="submit" className="primary_btn">Login</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="loginBottom">
                                    <p>Don’t have an account?<Link to="/Register"> Create new Account</Link></p>

                                </div>
                            </div>
                        </div>
                        <div className=" col-lg-6 d-lg-block d-none">
                            <div className="loginImage">
                                <img src={loginGirl} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!--End Login Page--> */}
        </>

    );
};


export default (Login);